<template>
  <Transition name="tw-modal">
    <WebModal
      hide-footer
      :width="1024"
      :height="800"
      dir="ltr"
    >
      <template #header>
        <div class="flex w-full justify-between">
          <div class="flex items-center text-20">
            {{ $i18n.locale === 'tr' ? 'ZOTLO MESAFELİ SATIŞ SÖZLEŞMESİ' : 'ZOTLO DISTANCE SALES AGREEMENT' }}
          </div>
          <div
            class="h-max cursor-pointer rounded-8 bg-neutral-100 p-[6px] transition-opacity hover:opacity-70"
            @click="closeModal"
          >
            <svg
              version="1.1"
              role="presentation"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="text-neutral-600"
            >
              <path
                d="M18.2997 5.71022C17.9097 5.32022 17.2797 5.32022 16.8897 5.71022L11.9997 10.5902L7.10973 5.70021C6.71973 5.31021 6.08973 5.31021 5.69973 5.70021C5.30973 6.09021 5.30973 6.72022 5.69973 7.11022L10.5897 12.0002L5.69973 16.8902C5.30973 17.2802 5.30973 17.9102 5.69973 18.3002C6.08973 18.6902 6.71973 18.6902 7.10973 18.3002L11.9997 13.4102L16.8897 18.3002C17.2797 18.6902 17.9097 18.6902 18.2997 18.3002C18.6897 17.9102 18.6897 17.2802 18.2997 16.8902L13.4097 12.0002L18.2997 7.11022C18.6797 6.73022 18.6797 6.09022 18.2997 5.71022Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </template>
      <template #body>
        <div v-if="$i18n.locale === 'tr'">
          <DistanceSalesAgreementModalTr :agreement-data="agreementData" />
        </div>
        <div v-else>
          <DistanceSalesAgreementModalEn :agreement-data="agreementData" />
        </div>
      </template>
    </WebModal>
  </Transition>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import type { AgreementData } from '@shared/elements/common/payment/types';
import { WebModal } from '@shared/components';
import DistanceSalesAgreementModalTr from './Tr.vue';
import DistanceSalesAgreementModalEn from './En.vue';

const emit = defineEmits(['close']);
defineProps({
  agreementData: { type: Object as PropType<AgreementData>, default: () => ({}) }
});

function closeModal() {
  emit('close');
}
</script>

<style lang="postcss" scoped>
:deep() {
  p,
  pre,
  span,
  a {
    font-family: 'Times New Roman', Times, serif;
    line-height: 1.35rem;
  }
}

:deep(.tw-modal-header) {
  @apply border-b-[1px] border-b-neutral-300 py-20 pl-24 pr-16;
}
:deep(.tw-table-body) {
  @apply bg-neutral-100 px-[16px] py-[18px];
}
:deep(.tw-modal-body) {
  @apply pt-8;
}

.tw-modal {
  &-enter-from,
  &-leave-to {
    @apply opacity-0;
  }
  &-enter-active &-container,
  &-leave-active &-container {
    @apply scale-110;
  }
}
:deep(.tw-modal-container) {
  @apply max-h-[600px];
}
</style>
